// Pagination override
$pagination-color: #00F;
$pagination-focus-color: #F00;

@import "BootstrapOverrides";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~react-datetime/css/react-datetime.css";

html, body {
  background-color: #DDD;
}

.nav-link:active {
  color: "#F00";
}

// Going beyond Bootstrap font-size helpers
.fs-7 {
  font-size: 0.75rem !important;
}

// List images
.list-image-square {
  object-fit: cover;
  height: 96px;
  width: 96px;
}
.list-missing-image-square {
  background-color: #EEE;
  border: 1px solid #DDD;
  height: 96px;
  width: 96px;
}

// Explore
// Info language rows
.exp-info-lang-row {
  padding-bottom: 5px;
  padding-top: 5px;
}